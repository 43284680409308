<template>
  <VanPopup v-model="show"
    style="background: #1C1C1C; border-radius: 25px; width:93%; max-width: 375px; min-width: 280px;" class="pop-wrap"
    @click-overlay="handleClose">

    <div class="work-wrap">
      <div class="close-icon">
        <div class="title">{{ $t('home.workList') }}</div>
        <van-icon name="cross" @click="handleClose" class="icon" />
      </div>
      <div class="work-list" v-if="workOrderList.length > 0">
        <div class="list">
          <div class="item">ID</div>
          <div class="item">{{ $t('home.createTime1') }}</div>
          <!-- <div class="item">{{ $t('home.updateTime') }}</div> -->
          <div class="item">{{ $t('home.status') }}</div>
          <div class="item">{{ $t('home.remark') }}</div>
        </div>
        <div class="list" v-for="(item, index) in workOrderList" :key="item.id">
        <div class="item">{{workOrderList.length - index}}</div>
          <div class="item">{{ item.createTime }}</div>
          <!-- <div class="item">{{ item.updateTime }}</div> -->
          <div class="item">
            <span v-if="item.status" class="yes">{{ $t('home.haveStatus') }}</span>
            <span v-else class="no">{{ $t('home.noStatus') }}</span>
          </div>
          <div class="item">{{ item.remark || '-'}}</div>
        </div>
      </div>
      <div class="no-data" v-else>{{ $t('home.noData') }}</div>
    </div>
  </VanPopup>
</template>

<script>
export default {
  name: '',
  props: ['workOrderList'],
  data () {
    return {
      show: true
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    }
  },
  mounted () {

  }
}
</script>

<style scoped lang="scss">
.pop-wrap {
  // padding: 10px 15px;
  max-height: 400px;
  overflow-y: scroll;

  .close-icon {
    position: relative;
    display: flex;
    // justify-content: flex-end;
    justify-content: center;
    padding-right: 25px;

    .icon {
      position: absolute;
      right: 25px;
    }
  }

  .work-wrap {
    margin-top: 25px;

    .work-name {
      padding-left: 15px;
    }

    .work-list {
      margin: 8px 15px;
      border-radius: 15px;
      // max-height: 200px;
      padding: 10px;
      font-size: 12px;

      .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.199);
        padding: 10px 0;

        &:first-child {
          border-bottom: 1px solid #fff;

        }

        .item {
          width: 25%;
          text-align: center;
        }

        .no {
          color: #662bfc;
        }

        .yes {
          color: #ffad43;
        }
      }
    }
  }

  .no-data {
    text-align: center;
    padding: 30px;
  }
}
</style>
