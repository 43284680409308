import request from '@/utils/request'

// 获取最后一条工单
export const queryLastWorkOrder = (signData) => {
  return request('GET', '/app/workOrder/getLastOne', '', true, '', signData)
}

export const queryWorkList = (params) => {
  return request('GET', '/app/workOrder/list', params, false)
}

export const queryCheckTip = (params) => {
  return request('GET', '/app/workOrder/checkTip', params, false)
}
