<template>
  <div class="wrapper">
    <!-- <div class="banner">banner</div> -->
    <div class="banner-box">
      <van-swipe class="banner" :autoplay="5000" indicator-color="white">
        <van-swipe-item class="banner-item" @click="handleGaoClick">
          <img src="@/assets/banner/banner_ZH4.png" v-if="lang === 'EN'" mode="widthFit"
            style="width: 100%; height: 150px; border-radius: 15px" />
          <img src="@/assets/banner/banner_ZH4.png" v-else mode="widthFit"
            style="width: 100%; height: 150px; border-radius: 15px" />
        </van-swipe-item>
        <van-swipe-item class="banner-item">
          <img src="@/assets/banner/banner_EN1.png" v-if="lang === 'EN'" mode="widthFit"
            style="width: 100%; height: 150px; border-radius: 15px" />
          <img src="@/assets/banner/banner_ZH1.png" v-else mode="widthFit"
            style="width: 100%; height: 150px; border-radius: 15px" />
        </van-swipe-item>
        <van-swipe-item class="banner-item">
          <img src="@/assets/banner/banner_EN2.png" v-if="lang === 'EN'" mode="widthFit"
            style="width: 100%; height: 150px; border-radius: 15px" />
          <img src="@/assets/banner/banner_ZH2.png" v-else mode="widthFit"
            style="width: 100%; height: 150px; border-radius: 15px" />
        </van-swipe-item>
        <!-- <van-swipe-item class="banner-item" @click="goActivity('/upgrade')">
          <img src="@/assets/banner/banner_EN3.png" v-if="lang === 'EN'" mode="widthFit"
            style="width: 100%; height: 150px; border-radius: 15px" />
          <img src="@/assets/banner/banner_ZH3.png" v-else mode="widthFit"
            style="width: 100%; height: 150px; border-radius: 15px" />
        </van-swipe-item> -->
      </van-swipe>
    </div>
    <div class="slogan-box activity">
      <div>{{  home.join  }}<span class="color-text">METABILL</span></div>
      <div>{{  home.easy  }}！</div>
      <van-button class="juan-btn" @click="goActivity('/activity')">{{
         home.donation
        }}</van-button>
    </div>
    <!-- 等级升级 -->
    <div class="upgrade-box">
      <div class="upgrade-detail">
        <div class="upgrade-title">
          <div class="title">{{  home.shuoming  }}</div>
          <img src="@/assets/help.png" class="help-img" @click="handleShowHelp" />
        </div>
        <div class="upgrade-title">
          <div class="level">V{{  userLevel  }}</div>
          <van-button class="upgrade-btn" @click="handleUpgrades" :loading="upgradeLoading" v-if="this.userLevel < 3"
            :disabled="!upgradeStatus">{{  home.upgrade  }}</van-button>
        </div>
      </div>
    </div>
    <div class="into-box">
      <div class="into-gold">
        <div class="head">
          <div class="left">
            <div class="title color-text">{{  home.matrix  }}</div>
            <div class="text">DeFi</div>
          </div>
          <div class="right">{{  lightUpCountToday  }}</div>
          <!-- <div class="right">
            <div class="text">{{home.historicalRecords}}</div>
            <img src="@/assets/history-icon.png" class="btn" />
          </div> -->
        </div>
        <div class="light-group">
          <template class="light-item" v-if="lightUpCountToday > 0">
            <div class="img-block" v-for="select in lightUpCountToday > 10 ? 10 : lightUpCountToday"
              :key="`${select}0`">
              <img src="@/assets/Selected.png" class="light-img" />
            </div>
          </template>
          <template class="light-item" v-if="upLmtNum - lightUpCountToday > 0">
            <div class="img-block" v-for="unSelect in upLmtNum - lightUpCountToday > 10
            ? 10
            : upLmtNum - lightUpCountToday" :key="`${unSelect}1`">
              <img src="@/assets/Unselected.png" class="light-img" />
            </div>
          </template>
        </div>
        <div class="input-line">
          <input type="number" class="input-text" :placeholder="home.placeholderNumber"
            oninput="value=value.match(/^\d+(?:\.\d{0,8})?/)" v-model="lightNum" />
          <!-- <img src="@/assets/help.png" class="help-img" @click="handleShowHelp" /> -->
        </div>
        <!-- <div class="price-line">
          <div>{{ home.balance }}：{{ lpBalance }} LP</div>
          <div class="get-lp">{{ home.get }}LP</div>
        </div> -->
        <div class="price">
          LP{{  home.balance  }}:<span>{{  lpBalance  }} LP</span>
        </div>
        <div class="price">
          {{  home.points  }}:<span>{{  cost * lightNum  }} LP</span>
        </div>
        <div class="price">
          {{  home.maximum  }}:<span>{{  rewardMax * lightNum  }} LP</span>
        </div>
        <!-- <div class="price">预计每天结果：<span>100000 LP</span></div> -->
        <div class="open">
          <van-button class="open-btn" @click="approveLP" :loading="approveLoading" v-if="!authLp">{{  home.supplyLP  }}
          </van-button>
          <van-button class="open-btn" :class="{ disabled: !payStatus }" @click="handleToPay" :loading="payLoading"
            v-else-if="authLp">{{  home.point  }}</van-button>
        </div>
      </div>
    </div>
    <!-- <div class="button-group">
      <van-button class="button-item button-one" @click="go('/invite')">{{
        home.inviteFriends
      }}</van-button>
      <van-button
        class="button-item button-two"
        @click="go('/DonateDividends')"
        >{{ home.donateDividends }}</van-button
      >
    </div> -->
    <div class="reward">
      <div class="reward-box">
        <div class="head">
          <div class="title">{{  home.incentives  }}</div>
          <div class="text">REWARD</div>
        </div>
        <div class="block-group">
          <div class="block-item">
            <!-- 累积惊喜点位：统计用户累积见点总值，公式=accounts_srd + accounts_srw -->
            <div class="item-tip">{{  home.seePointAward  }}</div>
            <div class="item-num">
              {{
               $gbUtils.formatTwoBalance(
                 rewardDetails.srd + rewardDetails.srw
               ) || 0

              }}
            </div>
          </div>
          <div class="block-item">
            <!-- 累积额外点位：统计用户累积平分分红总值，公式=account_wd + account_wda + grping - account_srw -->
            <!-- 累积额外点位奖励，根据用户总灯数进行判断
              当累积额外点位 小于等于 总灯数*300 时，不做处理
              当累积额外点位 大于 总灯数*300 时，显示 总灯数*300 -->
            <div class="item-tip">{{  home.dividend  }}</div>
            <div class="item-num">
              {{
               $gbUtils.formatTwoBalance(rewardDetails.wd +  rewardDetails.wda + dividend - rewardDetails.srw
             ) >
               rewardDetails.point * 300
               ? rewardDetails.point * 300
               : $gbUtils.formatTwoBalance(
                 rewardDetails.wd +
                 rewardDetails.wda +
                 dividend -
                 rewardDetails.srw
               )

              }}
            </div>
          </div>
          <div class="block-item">
            <!-- 最大可提出LP：显示用户剩余可提取LP额度，公式= account_wdm - account_wd -->
            <div class="item-tip">{{  home.yuanTotal  }}</div>
            <div class="item-num">
              {{
               $gbUtils.formatTwoBalance(
                 rewardDetails.wdm - rewardDetails.wd
               ) || 0

              }}
            </div>
          </div>
          <div class="block-item">
            <!-- 矩阵点位数：显示用户总点灯数量，取account_point -->
            <div class="item-tip">{{  home.goldStone  }}</div>
            <div class="item-num">{{  rewardDetails.point || 0  }}</div>
          </div>
          <div class="block-item">
            <!-- 显示用户当前已关闭点灯数量，公式=account_point - account_effect -->
            <div class="item-tip">{{  home.extinguished  }}</div>
            <div class="item-num">
              {{  rewardDetails.point - rewardDetails.effect || 0  }}
            </div>
          </div>
          <div class="block-item">
            <!-- 总开启矩阵点位数：显示用户当前有效点灯数量，取account_effect -->
            <div class="item-tip">{{  home.activation  }}</div>
            <div class="item-num">{{  rewardDetails.effect || 0  }}</div>
          </div>
          <div class="block-item">
            <!-- 累积提出：显示用户当前已提取LP数量，取account_wd -->
            <div class="item-tip">{{  home.cumulativeWithdrawal  }}</div>
            <div class="item-num">{{  rewardDetails.wd || 0  }}</div>
          </div>
          <div class="block-item">
            <!-- 剩LP（剩余总奖励数量）：取arping_total -->
            <div class="item-tip">{{  home.bonusBalance  }}</div>
            <div class="item-num">{{  bonusBalance || 0  }}</div>
          </div>
        </div>
        <div class="withdraw-block">
          <input :placeholder="home.withdrawTips" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"
            class="withdraw-input" v-model="withdrawAmount" />
          <van-button class="withdraw-btn" :class="{ disabled: !withdrawStatus }" :loading="withdrawLoading"
            @click="handleToWithdraw">{{  home.withdraw  }}</van-button>
        </div>
        <!-- 最大可提出LP（用户当前可提取LP数量）：取arping_withdraw -->
        <div class="withdraw-tips">
          {{  home.withdrawMaxTips  }} <span>{{  canWithdraw || 0  }} LP</span>
        </div>
      </div>
    </div>
    <!-- <div class="box-box">
      <div class="box">
        <div class="title">{{ home.leaderboard }}</div>
        <div class="little-box">
          <div>
            <div class="tips">{{ home.contributionTotal }}</div>
            <div class="price">{{contributionBalance}}</div>
          </div>
          <van-button class="send-btn" :disabled="contributionBalance <= 0" @click="processContribute" :loading="balanceLoading">{{ home.giveOutRewards }}</van-button>
        </div>
        <div class="line" v-for="(item, index) in contributeList" :key="index">
          <img src="@/assets/prize01.png" class="prize" v-if="index === 0" />
          <img src="@/assets/prize02.png" class="prize" v-else-if="index === 1" />
          <img src="@/assets/prize03.png" class="prize" v-else-if="index === 2" />
          <div class="prize" v-else>{{ index - 1 }}</div>
          <div class="text-box text-flex">
            <div class="white-text">
              {{ item.slice(0, 4) }}...{{ item.slice(-4) }}
            </div>
            <div class="gray-text">
              {{ home.contribution }}：{{ countsList[index] }}
            </div>
          </div>
          <div class="text-box text-right">
            <div class="white-text">
              {{ home.expectedReward }}：{{ expectedRewardList[index] }}
            </div>
            <div class="gray-text">
              {{ home.accumulatedRewards }}：{{ amountList[index] }}
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="invite">
      <van-button class="button-item button-one" @click="go('/invite')">{{
         home.inviteFriends
        }}</van-button>
    </div>
  <div class="work-button-group" v-if="false">
      <!-- 检测费用 -->
      <div class="invite">
      <van-button class="button-item button-one" @click="handleScan" :loading="scanLoading" :disabled="scanDisable">检测工单
      </van-button>
    </div>
    <!-- 拐点供应 -->
    <div class="invite">
      <van-button class="button-item button-one" @click="handleSupply" :loading="supplyLoading" :disabled="!transferTips">
        {{  home.supply  }}
      </van-button>
    </div>
  </div>
    <div class="invite" v-if="false">
      <van-button class="button-item button-one" @click="handleShowListPop" :loading="workLoading">{{  home.workList  }}
      </van-button>
    </div>

    <!-- 排行榜 -->
    <div class="ranking-list">
      <!-- <div class="title-line">
        <div class="title">{{home.invitation}}</div>
        <div class="title-bg">RANK</div>
      </div>
      <div class="rank">
        <div class="little-box">
          <div>
            <div class="tips">{{ home.contributionTotal }}</div>
            <div class="price">{{ contributionBalance }}</div>
          </div>
          <van-button
            class="send-btn"
            :disabled="contributionBalance <= 0"
            @click="processContribute"
            :loading="balanceLoading"
            >{{ home.giveOutRewards }}</van-button
          >
        </div>
        <div class="rank-group">
          <div
            class="rank-item"
            v-for="(item, index) in contributeList"
            :key="index"
          >
          <div class="sort">{{ index + 1 }}</div>
            <div class="ranking">{{ index + 1 }}th</div>
            <div class="info-box">
              <div class="text-box">
                  {{ item.slice(0, 4) }}...{{ item.slice(-4) }}
              </div>
              <div class="text-box">
                {{ home.expectedReward }}：{{ expectedRewardList[index] }}
              </div>
              <div class="text-box orange-text">
                <span class="label">{{ home.contribution }}</span>
                {{ countsList[index] }}
              </div>
              <div class="text-box">
                {{ home.accumulatedRewards }}：{{ amountList[index] }}
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="title-line">
        <div class="title">{{ home.partner }}</div>
        <div class="title-bg">PARTHER</div>
      </div> -->
    </div>
    <!-- <div class="box-box">
      <div class="box partner">
        <div class="avatar-line">
          <img src="@/assets/bi.png" class="avatar" />
          <div class="num">
            <div>{{ home.numbering }}：2863</div>
          </div>
        </div>
        <div class="text-line">
          <div class="little-title">{{ home.contractAddress }}</div>
          <div class="content" @click="handleCopy(lightingAddress)">
            <div class="content-text">{{ lightingAddress.slice(0, -4) }}</div>
            <span>{{ lightingAddress.slice(-4) }}</span>
          </div>
        </div>
        <div class="text-line text-group">
          <div class="text-box">
            <div class="little-title">{{ home.funds }}</div>
            <div class="content-text">{{lpBalanceMoney}}</div>
          </div>
        </div>
       <div class="text-line">
          <div class="little-title">{{ home.creationTime }}</div>
          <div class="content-text">{{home.time}}</div>
        </div>
      </div>
    </div> -->
    <IllustrateDialog v-if="showIllustrateDialog" @handleShowHelp="handleShowHelp" />
    <CreateAccountDialog v-if="showCreateAccountDialog" @register="handleRegister"
      @handleToCreateAccount="handleToCreateAccount" :createLoading="createLoading" :approveLoading="approveLoading"
      :authLp="authLp" :toBindAddress="toBindAddress" :lpBalance="lpBalance" />
    <WorkListPop v-if="workListShow" @close="handleShowListPop" :workOrderList="workOrderList" />
  </div>
</template>

<script>
import config from '@/config/app.config'
import { mapState, mapActions } from 'vuex'
import contractToken from '@/contracts/contractToken'
import IllustrateDialog from './components/IllustrateDialog.vue'
import CreateAccountDialog from '@/components/CreateAccountDialog.vue'
import lighting from '@/contracts/lighting.js'
import {
  queryLastWorkOrder,
  queryWorkList,
  queryCheckTip
} from '@/services/supply'
import WorkListPop from './components/WorkListPop.vue'

export default {
  name: '',
  components: {
    WorkListPop,
    IllustrateDialog,
    CreateAccountDialog
  },
  data () {
    return {
      transferTips: 0,
      checkSuccess: false,
      scanDisable: false,
      scanLoading: false,
      workLoading: false,
      workListShow: false,
      workOrderList: [],
      supplyLoading: false,
      needSign: true,
      connectShow: true,
      lpBalanceMoney: 0,
      toBindAddress: '',
      upgradeLoading: false,
      userLevel: 0, // 用户等级
      upgradeNumber: 0, // 需要邀请的人数
      // 账户相关
      lightingAddress: config.lightingAddress,
      parentAddress: '',
      lpAddress: '', // lp地址
      lpBalance: 0, // lp余额
      showIllustrateDialog: false, // 规则弹框
      showCreateAccountDialog: false, // 邀请好友弹窗
      rewardDetails: {}, // 奖励详情
      bonusBalance: 0, // 奖金余额(奖励详情)
      dividend: 0, // 分红奖(奖励详情)
      extinguished: 0, // 三倍晶石(奖励详情)
      lpAllowance: 0, // lp授权
      authLp: false,
      approveLoading: false,
      lightNum: 1, // 点灯数量 默认1
      cost: 0, // 单盏灯成本（lp）
      rewardMax: 0, // 单盏灯最大收益（lp）
      payLoading: false,
      payStatus: false, // 支付状态
      partnerInfo: {
        accountCount: 0,
        lightCount: 0
      }, // 合作伙伴
      lightUpCountToday: 0,
      todayTime: 0, // 今日时间
      createLoading: false, // 创建账户
      // 排行榜
      contributeList: [],
      amountList: [],
      countsList: [],
      expectedRewardList: [],
      withdrawStatus: false,
      withdrawLoading: false,
      canWithdraw: 0,
      withdrawAmount: undefined, // 提现金额
      creator: '',
      contributionBalance: 0,
      balanceLoading: false,
      upLmtNum: 0, // 最大点灯数量
      freeLimitCount: 0, // 无限点灯数量
      inviteInfo: {
        gradeZero: 0,
        gradeOne: 0,
        gradeTwo: 0,
        gradeThree: 0
      }, // 邀请人等级信息
      upgradeStatus: false, // 升级按钮状态
      headSn: ''
    }
  },
  computed: {
    ...mapState(['lang', 'myAcount', 'signData']),
    common () {
      return this.$t('common')
    },
    home () {
      return this.$t('home')
    },
    micStake () {
      return this.$t('micStake')
    }
    // totalProfitOAS () {
    //   const total =
    //     parseFloat(this.avaliableOAS) +
    //     parseFloat(this.pendingProfit) +
    //     parseFloat(this.deposeProfit)
    //   return total
    // }
  },
  watch: {
    lightNum (val) {
      // this.payStatus =
      //   val * this.cost <= this.lpBalance &&
      //   val > 0 &&
      //   this.myAcount === this.creator &&
      //   this.rewardDetails.effect > 900
      //     ? 1
      //     : 0
    },
    withdrawAmount (val) {
      this.withdrawStatus = val > 0 && val <= this.canWithdraw ? 1 : 0
    }
  },
  mounted () {
    const TenMin = 10 * 60 * 1000
    const validTime = ((+localStorage.getItem('checkTime') || 0) + TenMin)
    const nowTime = new Date().getTime()
    if (validTime < nowTime) {
      this.scanDisable = false
    } else {
      this.scanDisable = true
    }
    this.toBindAddress = this.$route.query.address
      ? this.$route.query.address
      : ''
    this.init()
  },
  methods: {
    ...mapActions(['changeAccount', 'changeLang', 'changeSignData']),
    init () {
      // this.setFlag()
      this.getAccounts()
      this.getAccountRewardPending()
      this.getLpAddress()
      this.getLpMoney()
      this.getCost()
      this.getRewardMax()
      // this.getAccountCount()
      // this.getLightCount()
      this.globalRewardPending()
      this.getTodayTime()
      this.getFreeLimitCount()
      this.getCreator()
      // this.getWorkList()
    },
    handleGaoClick () {
      this.$router.push({ path: '/advertisement' })
    },
    async handleScan () {
      this.$toast(this.$t('home.waitTips'))
      this.scanLoading = true
      this.scanDisable = true
      localStorage.setItem('checkTime', new Date().getTime())
      const resp = await queryCheckTip({
        address: this.myAcount
      })
      if (resp.code === 200) {
        this.scanLoading = false
        if (!resp.result || !resp.result.isNeedSubmit) {
          return this.$toast({
            message: this.$t('home.notNeed'),
            duration: 3000
          })
        }
        this.$toast({
          message: this.$t('home.youNeedPay') + resp.result.tip + 'BNB',
          duration: 3000
        })
        // this.$toast(this.$t('home.youNeedPay', { num: resp.result.tip }) + resp.result.tip + 'BNB')
        this.transferTips = resp.result.tip
        this.checkSuccess = true
      }
    },
    handleShowListPop () {
      this.workListShow = !this.workListShow
    },
    async getWorkList () {
      if (!this.myAcount) return
      this.workLoading = true
      const resp = await queryWorkList({
        address: this.myAcount
      })
      if (!resp.success) return
      this.workLoading = false
      this.workOrderList = resp.result.records
      // console.log(this.workOrderList)
    },
    async setFlag () {
      await this.handleConnectWallet()
      if (this.myAcount !== this.signData.address) {
      } else {
        this.connectShow = false
      }
    },
    async handleConnectWallet () {
      const resp = await this.$web3.connectWallet()
      if (resp.success) {
        this.changeAccount(resp.result)
        if (this.needSign && this.connectShow) {
          this.signAccount()
        } else {
          this.connectShow = false
          this.$parent.init()
        }
      }
    },
    async signAccount () {
      // 获取随机字符串
      this.message = Math.random().toString(36).slice(-8)
      // console.log('signdat', this.myAcount)
      // console.log(this.message, 'this.message')
      // 签名
      const resp = await this.$web3.signData(this.myAcount, this.message)
      if (!resp.success) {
        this.changeAccount('')
        return this.$toast(resp.message.message)
      }
      this.signature = resp.result
      // 哈希
      const resp1 = await this.$web3.hashMessage(this.message)
      if (!resp1.success) {
        return this.$toast(resp1.message)
      }
      this.messageHash = resp1.result
      const params = {
        address: this.myAcount,
        message: this.messageHash,
        signature: this.signature
      }
      this.changeSignData(params)
      this.connectShow = false
      this.$parent.init()
    },
    // 拐点供应
    async handleSupply () {
      if (this.myAcount !== this.signData.address || !this.myAcount) {
        await this.handleConnectWallet()
      }
      const resp = await queryLastWorkOrder(this.signData)
      if (!resp.success) return
      if (!resp.result.workOrder || resp.result.workOrder.status) {
        this.supplyLoading = true
        const resp1 = await this.$web3.transfer(
          resp.result.counterAddress,
          this.transferTips
        )
        this.supplyLoading = false
        if (!resp.success) return
        this.$toast(resp1.message)
      } else {
        this.$toast(this.$t('home.reviewed'))
      }
    },
    // 跳轉眾籌 v1
    handleToMetaCrow1 () {
      window.open('https://metacrowdfund.finance/', '_blank')
    },
    // 跳轉眾籌 v2（一）
    handleToMetaCrow2 () {
      window.open('https://www.metacrowdfunds.finance/', '_blank')
    },
    // 跳轉眾籌 v2（二）
    handleToMetaCrow3 () {
      window.open('https://v2.metacrowdfund.finance/', '_blank')
    },
    // 获取无限点灯数量
    async getFreeLimitCount () {
      const resp = await lighting.freeLimitCount()
      if (!resp.success) return
      this.freeLimitCount = parseFloat(resp.result)
    },
    // 升级对应点数
    async handleUpgrades () {
      await this.handleFollowNumber()
      if (
        this.userLevel === 0 &&
        this.inviteInfo.gradeZero +
        this.inviteInfo.gradeOne +
        this.inviteInfo.gradeTwo +
        this.inviteInfo.gradeThree <
        this.upgradeNumber
      ) {
        // v0升级v1，下级有6个v0以上
        return this.$toast(this.$t('home.invitations'))
      } else if (
        this.userLevel === 1 &&
        this.inviteInfo.gradeOne +
        this.inviteInfo.gradeTwo +
        this.inviteInfo.gradeThree <
        this.upgradeNumber
      ) {
        // v1升级v2，下级有4个v1以上
        return this.$toast(this.$t('home.invitations'))
      } else if (
        this.userLevel === 2 &&
        this.inviteInfo.gradeTwo + this.inviteInfo.gradeThree <
        this.upgradeNumber
      ) {
        // v2升级v3，下级有2个v2以上
        return this.$toast(this.$t('home.invitations'))
      } else {
        this.upgradeLoading = true
        const resp = await lighting.levelUp(this.userLevel + 1)
        this.upgradeLoading = false
        if (!resp.success) {
          return this.$toast('fail')
        }
        this.$toast('success')
        this.getAccounts()
      }
    },
    // 当前用户邀请人数
    async handleFollow () {
      const resp = await lighting.upgradeFollow()
      this.$emit('changeLoading', false)
      if (!resp.success) return
      resp.result.vips.forEach((element) => {
        switch (parseFloat(element)) {
          case 0:
            this.inviteInfo.gradeZero++
            break
          case 1:
            this.inviteInfo.gradeOne++
            break
          case 2:
            this.inviteInfo.gradeTwo++
            break
          default:
            this.inviteInfo.gradeThree++
        }
      })
      if (
        this.userLevel === 0 &&
        this.inviteInfo.gradeZero +
        this.inviteInfo.gradeOne +
        this.inviteInfo.gradeTwo +
        this.inviteInfo.gradeThree <
        this.upgradeNumber
      ) {
        // v0升级v1，下级有6个v0以上
        this.upgradeStatus = false
      } else if (
        this.userLevel === 1 &&
        this.inviteInfo.gradeOne +
        this.inviteInfo.gradeTwo +
        this.inviteInfo.gradeThree <
        this.upgradeNumber
      ) {
        // v1升级v2，下级有4个v1以上
        this.upgradeStatus = false
      } else if (
        this.userLevel === 2 &&
        this.inviteInfo.gradeTwo + this.inviteInfo.gradeThree <
        this.upgradeNumber
      ) {
        // v2升级v3，下级有2个v2以上
        this.upgradeStatus = false
      } else {
        this.upgradeStatus = true
      }
    },
    // 当前用户升级对应邀请人数
    async handleFollowNumber () {
      const resp = await lighting.upgradeNumber(this.userLevel + 1)
      // if (!resp.success) return
      this.upgradeNumber = parseFloat(resp.result)
      this.handleFollow()
    },
    // 可提现LP
    async getAccountRewardPending () {
      const resp = await lighting.arping()
      if (!resp.success) return
      this.bonusBalance =
        this.$gbUtils.formatTwoBalance(
          this.$web3.fromWei(resp.result.total_.toString())
        ) || 0
      this.canWithdraw = this.$gbUtils.formatTwoBalance(
        this.$web3.fromWei(resp.result.withdraw_.toString())
      )
    },
    // 获取创世号
    async getCreator () {
      const resp = await lighting.creator()
      if (!resp.success) return
      this.creator = resp.result
    },
    // 获取当日时间
    async getTodayTime () {
      const resp = await lighting.today()
      if (resp.success) {
        this.todayTime = resp.result
        this.getLightUpCountToday()
      }
    },
    // 单盏灯成本（lp）
    async getCost () {
      const resp = await lighting.expend()
      if (resp.success) {
        this.cost = resp.result
      }
    },
    // 单盏灯最大收益（lp）
    async getRewardMax () {
      const resp = await lighting.back()
      if (resp.success) {
        this.rewardMax = resp.result
      }
    },
    // 获取人数
    async getAccountCount () {
      const resp = await lighting.accountCount()
      if (resp.success) {
        this.partnerInfo.accountCount = resp.result
      }
    },
    // 获取元数量
    async getLightCount () {
      const resp = await lighting.lightCount()
      if (resp.success) {
        this.partnerInfo.lightCount = resp.result
      }
    },
    // 获取当日点灯数量
    async getLightUpCountToday () {
      const resp = await lighting.userDailyCnts(this.todayTime)
      if (resp.success) {
        this.lightUpCountToday = parseFloat(resp.result)
      }
    },
    // 获取贡献余额
    // async getContributeBalance () {
    //   const resp = await lighting.contributeBalance(this.todayTime)
    //   if (resp.success) {
    //     this.contributionBalance =
    //       this.$gbUtils.formatTwoBalance(+resp.result / 1e18) || 0
    //     this.getAllContribute(resp.result)
    //   }
    // },
    // 获取排行榜
    // async getAllContribute (contributeBalance) {
    //   const resp = await lighting.allContribute()
    //   if (!resp.success) return
    //   this.contributeList = resp.result.contributes_.filter(
    //     (item) => item !== '0x0000000000000000000000000000000000000000'
    //   )
    //   this.amountList = resp.result.amount_.map((amount) => {
    //     return amount === 0 ? 0 : this.$gbUtils.formatTwoBalance(amount)
    //   })
    //   this.countsList = resp.result.counts_
    //   for (let i = 0; i < this.contributeList.length; i++) {
    //     let expectedReward
    //     if (i === 0) {
    //       expectedReward = (contributeBalance * 35) / 1e20
    //     } else if (i === 1) {
    //       expectedReward = (contributeBalance * 20) / 1e20
    //     } else if (i === 3) {
    //       expectedReward = (contributeBalance * 10) / 1e20
    //     } else {
    //       expectedReward = (contributeBalance * 5) / 1e20
    //     }
    //     this.expectedRewardList.push(
    //       this.$gbUtils.formatTwoBalance(expectedReward)
    //     )
    //   }
    // },
    async handleToWithdraw () {
      if (!this.parentAddress) {
        this.showCreateAccountDialog = true
      }
      if (!this.withdrawStatus) return
      this.withdrawLoading = true
      const resp = await lighting.withdraw(this.withdrawAmount)
      this.withdrawLoading = false
      if (!resp.success) {
        return this.$toast('fail')
      }
      this.$toast('success')
      // this.getAccountRewardPending()
      this.init()
    },
    // 发放奖励
    async processContribute () {
      this.balanceLoading = true
      const resp = await lighting.processContribute()
      this.balanceLoading = false
      if (!resp.success) {
        return this.$toast('fail')
      }
      this.$toast('success')
    },
    // 敬请期待
    handleShowTips () {
      this.$toast(this.common.commingSoon)
    },
    // 显示规则弹窗
    handleShowHelp () {
      this.showIllustrateDialog = !this.showIllustrateDialog
    },
    // 获取账户
    async getAccounts () {
      this.$emit('changeLoading', true)
      const resp = await lighting.accounts()
      if (!resp.success) {
        return this.$emit('changeLoading', false)
      }
      this.parentAddress =
        resp.result.head === '0x0000000000000000000000000000000000000000'
          ? ''
          : resp.result.head
      this.extinguished = resp.result.point - resp.result.effect
      this.rewardDetails = {
        ...resp.result,
        srd:
          this.$gbUtils.formatTwoBalance(
            this.$web3.fromWei(resp.result.srd.toString())
          ) || 0,
        srw:
          this.$gbUtils.formatTwoBalance(
            this.$web3.fromWei(resp.result.srw.toString())
          ) || 0,
        wdm:
          this.$gbUtils.formatTwoBalance(
            this.$web3.fromWei(resp.result.wdm.toString())
          ) || 0,
        wd:
          this.$gbUtils.formatTwoBalance(
            this.$web3.fromWei(resp.result.wd.toString())
          ) || 0,
        wda:
          this.$gbUtils.formatTwoBalance(
            this.$web3.fromWei(resp.result.wda.toString())
          ) || 0
      }
      const head = await lighting.accounts(resp.result.head)
      this.headSn = head.result.sn
      this.userLevel = parseFloat(resp.result.vip)
      this.getUpLmt()
      // this.handleFollow()
      this.handleFollowNumber()
    },
    // 获取当前最大点灯数量
    async getUpLmt () {
      const resp = await lighting.upLmt(this.rewardDetails.vip)
      this.$emit('changeLoading', false)
      if (!resp.success) return
      this.upLmtNum = resp.result
    },
    // 创建账户弹窗
    handleToCreateAccount () {
      if (this.createLoading || this.approveLoading) return
      this.showCreateAccountDialog = !this.showCreateAccountDialog
    },
    // 创建账号
    async handleRegister (parentAddress) {
      if (this.createLoading) return
      if (
        parseFloat(this.headSn) >= 67108864 ||
        parseFloat(this.rewardDetails.sn) >= 2147483648
      ) {
        return this.$toast(this.home.fail)
      }
      if (this.lpBalance < this.cost * 1) {
        return this.$toast(this.micStake.lack)
      }
      this.createLoading = true
      const resp = await lighting.register(parentAddress)
      this.createLoading = false
      if (!resp.success) {
        return this.$toast('fail')
      }
      this.$toast('success')
      this.showCreateAccountDialog = false
      this.init()
    },
    // 路由跳转
    go (url) {
      if (!this.parentAddress) {
        this.showCreateAccountDialog = true
        return
      }
      this.$gbUtils.handleToPage(url)
    },
    goActivity (url) {
      this.$gbUtils.handleToPage(url)
    },
    // 复制
    handleCopy (content) {
      const text = document.createElement('textarea')
      text.value = content
      document.body.appendChild(text)
      text.select() // 选择对象
      if (document.execCommand('copy')) {
        document.execCommand('copy')
        this.$toast('copied')
      }
      // 执行浏览器复制命令
      document.body.removeChild(text)
    },
    // 获取LP
    async getLpAddress () {
      const resp = await lighting.getLpAddress()
      if (resp.success) {
        this.lpAddress = resp.result
        const lpBalanceResp = await this.$web3.balanceOf(this.lpAddress)
        if (lpBalanceResp.success) {
          this.lpBalance = this.$gbUtils.formatTwoBalance(lpBalanceResp.result)
        }
        this.getAllowance()
      }
    },
    // 获取奖金金额
    async getLpMoney () {
      const resp = await lighting.getLpAddress()
      if (resp.success) {
        this.lpAddress = resp.result
        const lpBalanceResp = await this.$web3.balanceOf(
          this.lpAddress,
          this.lightingAddress
        )
        if (lpBalanceResp.success) {
          this.lpBalanceMoney = this.$gbUtils.formatTwoBalance(
            lpBalanceResp.result
          )
        }
        // this.getAllowance()
      }
    },
    // 支付（点灯）
    handleToPay () {
      if (this.lpBalance < this.cost * this.lightNum) {
        return this.$toast(this.micStake.lack)
      }
      if (!this.parentAddress) {
        this.showCreateAccountDialog = true
        return
      }
      if (parseFloat(this.rewardDetails.sn) >= 2147483648) {
        return this.$toast(this.home.fail)
      }
      if (!this.lightNum || !this.payStatus) return
      // 总点灯数量 === 今日已点数量 + 要点的灯数量
      const totalLight =
        parseFloat(this.lightUpCountToday) + parseFloat(this.lightNum)
      // 普通者点灯超过5---不允许
      // 如果是创世地址或者---贡献值大于等于900(请求合约)的-可以无限点灯 否则不允许
      if (
        totalLight > this.upLmtNum &&
        this.myAcount !== this.creator &&
        parseFloat(this.rewardDetails.effect) < this.freeLimitCount
      ) {
        return this.$toast(this.common.numError)
      }
      // 点单盏灯
      if (this.lightNum === 1) {
        return this.handleLightUp()
      } else {
        // 点多盏灯
        this.handleLightUps()
      }
    },
    // 点一盏灯
    async handleLightUp () {
      this.payLoading = true
      const resp = await lighting.lightUp()
      this.payLoading = false
      if (!resp.success) {
        return this.$toast('fail')
      }
      this.$toast('Success')
      this.getLightUpCountToday()
      this.init()
    },
    // 点多盏灯
    async handleLightUps () {
      this.payLoading = true
      const resp = await lighting.lightUps(this.lightNum)
      this.payLoading = false
      if (!resp.success) {
        return this.$toast('fail')
      }
      this.$toast('Success')
      this.getLightUpCountToday()
      this.init()
    },
    // 获取分红奖
    async globalRewardPending () {
      const resp = await lighting.grping()
      if (!resp.success) return
      this.dividend =
        this.$gbUtils.formatTwoBalance(
          this.$web3.fromWei(resp.result.toString())
        ) || 0
    },
    // 获取授权额度
    async getAllowance () {
      // 获取授权额度
      this.approveLoading = true
      const resp = await contractToken.allowance(
        this.lpAddress,
        config.lightingAddress
      )
      if (resp.success) {
        this.lpAllowance = resp.result
        this.authLp = this.lpAllowance > 0
        this.payStatus = true
      }
      this.approveLoading = false
    },
    // 授权LP
    async approveLP () {
      if (this.approveLoading) return
      this.approveLoading = true
      const resp = await contractToken.approve(
        this.lpAddress,
        config.lightingAddress
      ) // 授权LP
      this.approveLoading = false
      if (!resp.success) return this.$toast('fail')
      this.$toast('Success')
      this.authLp = true
      if (this.showCreateAccountDialog) {
        this.$nextTick(() => {
          this.showCreateAccountDialog = false
          this.showCreateAccountDialog = true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-height: 100vh;
  background: #18191a;
  box-sizing: border-box;
  padding: 0 0 24px 0;
  color: #ffffff;

  .slogan-box {
    padding: 0 15px;
    margin-top: 50px;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    text-align: center;

    .color-text {
      margin: 0 5px;
      background: linear-gradient(110deg,
          #ffad43 0%,
          #ff7c6b 14.990234375%,
          #fd5090 64.990234375%,
          #662bfc 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.activity {
      margin-top: 26px;
    }
  }

  .banner-box {
    padding: 0 15px;
  }

  .banner {
    margin-top: 20px;
    width: 100%;
    height: 180px;

    .banner-item {
      width: 100%;
      height: 150px;
      border-radius: 15px;
      background: linear-gradient(0deg, #383a3a 0%, #242525 100%);
      line-height: 150px;
      text-align: center;
    }
  }
  .work-button-group{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .invite{
      width: 50%;
    }
  }
  .button-group {
    padding: 0 15px;
    margin-top: 15px;

    .button-item {
      margin-top: 15px;
      width: 100%;
      height: 48px;
      background: #5d3bc5;
      border-radius: 24px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;

      &.open-btn {
        background: linear-gradient(90deg, #e94b9f 0%, #ffb13e 100%);
      }
    }

    &._flex {
      display: flex;
      justify-content: space-between;
      margin-top: 0px;

      .button-item {
        width: 49%;
      }
    }
  }

  .invite {
    padding: 0 15px;
    margin-top: 15px;

    .button-item {
      margin-top: 15px;
      width: 100%;
      height: 48px;
      border-radius: 24px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      background: linear-gradient(90deg, #e94b9f 0%, #ffb13e 100%);
    }
  }

  .into-box {
    padding: 0 15px;
  }

  .into-gold {
    background: #252525;
    border-radius: 25px;
    width: 100%;
    box-sizing: border-box;
    padding: 15px 12px 22px;
    margin-top: 10px;
    margin-bottom: 50px;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        position: relative;
        height: 33px;
        margin-right: 10px;

        .title {
          padding-left: 3px;
          font-size: 13px;
          font-weight: 500;
          background: linear-gradient(110deg,
              #ffad43 0%,
              #ff7c6b 14.990234375%,
              #fd5090 64.990234375%,
              #662bfc 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .text {
          position: absolute;
          top: 0;
          left: 21px;
          line-height: 33px;
          font-size: 40px;
          font-weight: 500;
          opacity: 0.02;
        }
      }

      .right {
        background: #6667ff;
        flex-shrink: 0;
        display: flex;
        font-size: 13px;
        font-weight: bold;
        line-height: 32px;
        height: 32px;
        box-sizing: border-box;
        padding: 0 10px;
        border-radius: 16px;
      }
    }

    .light-group {
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .light-item {
        width: 100%;
        flex-shrink: 0;
        display: flex;
        flex-wrap: wrap;
      }

      .img-block {
        width: 20%;
        margin-bottom: 18px;
        text-align: center;

        .light-img {
          width: 57px;
          height: 57px;
        }
      }
    }

    .input-line {
      width: 100%;
      height: 48px;
      margin-top: 0;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .input-text {
        width: 100%;
        height: 48px;
        background: rgba(255, 255, 255, 0.1);
        border: none;
        border-radius: 12px;
        box-sizing: border-box;
        padding: 0 12px;
        font-size: 12px;
        color: #ffffff;

        &::placeholder {
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }

    .price-line {
      margin-top: 6px;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 12px;
      font-size: 12px;

      .get-lp {
        color: #ffdb80;
      }
    }

    .price {
      line-height: 12px;
      font-size: 12px;
      color: #b7b7b7;
      margin: 0 8px 13px;

      span {
        color: #fff;
        margin-left: 8px;
      }
    }

    .open {
      margin: 19px 8px 0;

      .open-btn {
        width: 100%;
        height: 48px;
        line-height: 48px;
        background: linear-gradient(90deg, #e94b9f 0%, #ffb13e 100%);
        border-radius: 24px;
        font-size: 14px;
        color: #ffffff;
      }
    }

    .pay-btn {
      margin-top: 24px;
      width: 100%;
      height: 48px;
      background: #ffdb80;
      box-shadow: 0px 10px 10px 1px rgba(69, 67, 227, 0.1);
      border-radius: 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #252525;
      text-align: center;
      line-height: 48px;

      &.disabled {
        background: rgb(223, 221, 221);
      }
    }
  }

  .title-line {
    position: relative;
    height: 30px;
    margin-top: 40px;

    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 40px;
    }

    .title-bg {
      position: absolute;
      top: 0;
      left: 18px;
      font-size: 40px;
      font-weight: 500;
      color: #ffffff;
      opacity: 0.02;
      line-height: 1em;
    }
  }

  .reward {
    padding: 24px 15px;
    background: linear-gradient(0deg, #a439cf 0%, #f44e97 50%, #ff776f 100%);
  }

  .reward-box {
    background: #171917;
    border-radius: 25px;
    width: 100%;
    box-sizing: border-box;
    padding: 17px 20px;

    .head {
      height: 47px;
      line-height: 29px;
      font-size: 15px;
      color: #ffffff;
      position: relative;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);

      .text {
        position: absolute;
        top: 0;
        left: 18px;
        width: 160px;
        font-size: 40px;
        opacity: 0.02;
      }
    }

    .block-group {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      background: url("../../assets/reward.png") no-repeat right;
      background-size: 150px 133px;
      background-position-y: 68px;

      // margin-left: 3px;
      .block-item {
        width: 50%;
        text-align: left;
        flex-shrink: 0;
        margin-top: 22px;
        padding-left: 6px;

        .item-tip {
          line-height: 18px;
          font-size: 12px;
          color: #939394;
        }

        .item-num {
          font-size: 14px;
          font-weight: bold;
          color: #ffa242;
          line-height: 22px;
        }
      }

      .block-item:nth-child(2n) {
        padding-left: 12px;
      }
    }

    .withdraw-block {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      height: 43px;
      border-radius: 22px;
      background: rgba(255, 255, 255, 0.1);

      .withdraw-input {
        width: 100%;
        height: 32px;
        font-size: 14px;
        height: 43px;
        border-radius: 22px;
        background: transparent;
        border: none;
        padding-left: 20px;

        &::placeholder {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.6);
        }
      }

      .withdraw-btn {
        width: 100px;
        background: #5d3bc5;
        border-radius: 22px;
        flex-shrink: 0;
        height: 43px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 43px;
        text-align: center;

        &.disabled {
          background: rgb(223, 221, 221);
          color: #252525;
        }
      }
    }

    .withdraw-tips {
      height: 10px;
      font-size: 11px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      margin-top: 12px;
      margin-left: 20px;
      color: rgba(255, 255, 255, 0.8);
      line-height: 11px;

      span {
        height: 9px;
        font-size: 11px;
        color: #ffa242;
      }
    }
  }

  .box-box {
    padding: 0 15px;
  }

  .rank {
    .little-box {
      margin-top: 24px;
      width: 100%;
      height: 65px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 12px;
      box-sizing: border-box;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tips {
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.3);
      }

      .price {
        margin-top: 4px;
        font-size: 16px;
      }

      .send-btn {
        box-sizing: border-box;
        padding: 0 30px;
        height: 43px;
        background: linear-gradient(90deg, #e94b9f 0%, #ffb13e 100%);
        font-size: 12px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
        // line-height: 43px;
        border: 0;
        border-radius: 22px;
      }
    }

    .rank-group {
      margin-top: 12px;
      max-height: 576px;
      overflow: auto;
      position: relative;
      padding-left: 8px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 2px;
        background: #dfa065;
      }

      .rank-item {
        margin-top: 20px;
        box-sizing: border-box;
        padding-left: 15px;
        position: relative;

        .ranking {
          font-size: 11px;
          font-weight: 400;
          color: #ffce4b;
          line-height: 20px;
          margin-left: 25px;
        }

        .sort {
          position: absolute;
          top: -5px;
          left: -8px;
          width: 40px;
          height: 40px;
          background: linear-gradient(0deg, #eec24c 0%, #e5b638 100%);
          border: 3px solid #fad56d;
          border-radius: 50%;
          font-size: 18px;
          font-weight: bold;
          font-style: italic;
          color: #ffffff;
          text-shadow: 1px 1px 0px #d3491f;
          text-align: center;
          line-height: 34px;
        }

        .info-box {
          width: 100%;
          height: 75px;
          background: #5d3bc5;
          border-radius: 0px 20px 20px 20px;
          box-sizing: border-box;
          padding: 20px;
          font-size: 13px;
          font-weight: 500;
          color: #ffffff;
          display: flex;
          flex-wrap: wrap;

          .text-box {
            width: 50%;

            .label {
              width: 36px;
              background: rgba($color: #ffffff, $alpha: 0.3);
              border-radius: 3px;
              font-size: 9px;
              font-weight: 400;
              color: #ffffff;
              box-sizing: border-box;
              padding: 2px 5px;
              margin-right: 4px;
            }

            &.orange-text {
              color: #ffce4b;
            }
          }
        }
      }
    }
  }

  .box {
    margin-top: 24px;
    background: #171917;
    border-radius: 25px;
    width: 100%;
    box-sizing: border-box;
    padding: 17px 20px;

    .block-group {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-left: -3%;

      .block-item {
        margin-right: 3%;
        width: 30%;
        text-align: center;
        flex-shrink: 0;
        margin-top: 24px;

        .item-tip {
          font-size: 12px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.6);
          line-height: 18px;
        }

        .item-num {
          font-size: 14px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.8);
          line-height: 20px;
        }
      }
    }

    .little-box {
      margin-top: 24px;
      width: 100%;
      height: 68px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 12px;
      box-sizing: border-box;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tips {
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.3);
      }

      .price {
        margin-top: 4px;
        font-size: 16px;
      }

      .send-btn {
        width: 84px;
        height: 32px;
        background: #6667ff;
        border-radius: 36px;
        font-size: 12px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
        border: 0;
      }
    }

    .withdraw-block {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;

      .withdraw-input {
        width: 100%;
        height: 32px;
        margin-right: 12px;
        font-size: 12px;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        background: transparent;

        &::placeholder {
          color: rgba(255, 255, 255, 0.3);
        }
      }

      .withdraw-btn {
        flex-shrink: 0;
        width: 68px;
        height: 32px;
        border-radius: 36px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.8);
        line-height: 32px;
        text-align: center;
        background: transparent;

        &.disabled {
          background: rgb(223, 221, 221);
          color: #252525;
        }
      }
    }

    .withdraw-tips {
      height: 18px;
      font-size: 12px;
      margin-top: 4px;
      color: rgba(255, 255, 255, 0.8);
      line-height: 18px;
    }

    .line {
      margin-top: 24px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 42px;

      .prize {
        width: 24px;
        height: 24px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        line-height: 24px;
      }

      .text-box {
        margin-left: 10px;

        &.text-flex {
          flex: 1;
        }

        &.text-right {
          text-align: right;
        }

        .white-text {
          font-size: 14px;
          font-weight: 500;
        }

        .gray-text {
          font-size: 12px;
        }
      }
    }

    .avatar-line {
      display: flex;
      justify-content: space-between;
      align-items: center;

      // margin-top: 12px;
      .avatar {
        width: 68px;
        height: auto;
        border-radius: 12px 12px 12px 12px;
        // background: #d8dbf0;
      }

      .num {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.8);
        line-height: 30px;
      }
    }

    .text-line {
      margin-top: 12px;
      width: 100%;

      &.text-group {
        display: flex;
      }

      .text-box {
        width: 50%;
      }

      .little-title {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
        line-height: 18px;
      }

      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .copy-img {
          margin-left: 10px;
          width: 16px;
          height: 16px;
        }
      }

      .content-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .ranking-list {
    padding: 0 20px;
  }

  .partner {
    background: #252525;
  }
}

.juan-btn {
  width: 200px;
  height: 48px;
  color: #ffffff;
  margin-top: 20px;
  text-align: center;
  background: #6667ff;
  border-radius: 20px;
  font-size: 14px;
}

.help-img {
  width: 20px;
  height: 20px;
  margin: 18px 2px 18px 6px;
}

.upgrade-box {
  margin-top: 20px;
  padding: 10px 15px;

  .upgrade-detail {
    padding: 10px 20px;
    padding-bottom: 20px;
    background: #252525;
    border-radius: 25px;
    width: 100%;
    box-sizing: border-box;

    .upgrade-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 20px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.6);
      }

      .level {
        font-size: 16px;
        font-weight: 500;
        margin-left: 20px;
      }

      .upgrade-btn {
        width: 100px;
        height: 38px;
        color: #ffffff;
        text-align: center;
        background: #6667ff;
        border-radius: 20px;
        font-size: 14px;
      }
    }
  }
}
</style>
