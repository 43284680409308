<template>
  <div class="dialog-wrapper" @touchmove.prevent>
    <div class="dialog">
      <div class="title">{{home.explain}}</div>
      <div class="content">
        <p>1. {{home.tipone}}</p>
        <p>2. {{home.tiptwo}}</p>
        <p>3. {{home.tipthree}}</p>
        <p>4. {{home.tipFour}}</p>
      </div>
    </div>
    <img src="@/assets/close.png" class="delete-icon" @click="handleToClose" />
  </div>
</template>

<script>
export default {
  name: 'IllustrateDialog',
  computed: {
    home () {
      return this.$t('home')
    }
  },
  methods: {
    handleToClose () {
      this.$emit('handleShowHelp')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  bottom: 0;
  max-width: 450px;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  text-align: center;
  .dialog {
    text-align: left;
    width: 303px;
    background: #ffffff;
    border-radius: 24px;
    margin: 224px auto 0;
    box-sizing: border-box;
    padding: 24px;
    color: #000000;
    .title {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
    }
    .content {
      font-size: 14px;
    }
  }
  .delete-icon {
    margin: 30px auto 0;
    width: 30px;
    height: 30px;
  }
}
</style>
